<script setup>
import { onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { isMobile } from 'o365.GlobalState.ts';

const props = defineProps({
    sjaID: Number,
    readOnly: Boolean
});

const dsRisks = getOrCreateDataObject({
    id: `dsRisks_${props.sjaID}`,
    uniqueTable: 'atbl_SJA_SJARisks',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJARisks',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'Risk_ID', },
        { name: 'Risk', },
        { name: 'UncertaintyColor', },
    ],
    whereClause: "SJA_ID = " + props.sjaID
});

const dsRisksLkp = getOrCreateDataObject({
    id: `dsRisksLkp_${props.sjaID}`,
    maxRecords: 25,
    viewName: 'aviw_RiskManager_Uncertainties',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'idPath', },
        { name: 'Name', },
        { name: 'OrgUnit', }
    ],
});

function addRisk(pRow) {
    dsRisks.createNew({
        SJA_ID: props.sjaID,
        Risk_ID: pRow.ID
    });
}

onMounted(() => {
    dsRisks.load();
})

</script>

<template>
    <ODataLookup v-if="!props.readOnly" :data-object="dsRisksLkp" :bind="sel => { addRisk(sel); }" :noClear="true" contextField="IdPath">
        <OColumn field="ID" :headerName="$t('ID')" width="80"></OColumn>
        <OColumn field="Name" :headerName="$t('Name')" width="300"></OColumn>
        <OColumn field="OrgUnit" :headerName="$t('Org Unit')" width="300"></OColumn>
        <template #target="scope" @click="scope.open">
            <button :ref="scope.target" class="p-0 btn btn-link">
                <div class="d-flex align-items-center" :title="$t('Link a risk to the SJA')">
                    <h5 class="m-0">{{ $t('Risks') }}</h5>
                    <i class="mx-1 bi bi-plus-circle-fill fs-5"></i>
                </div>
            </button>
        </template>
    </ODataLookup>

    <div v-if="props.readOnly" class="d-flex">
        <h5>{{ $t('Risks') }}</h5>
    </div>

    <hr class="m-0" />
    <div class="d-flex flex-wrap mt-2">
        <div v-for="(item, index) in dsRisks.data" :key="index" class="d-flex">
            <div class="mt-2 me-4" v-if="!item.isNewRecord">
                <span class="d-inline-flex align-items-center">
                    <span class="rounded border align-middle me-1" style="width:17px; height:17px;"
                        :style="{ background: item.UncertaintyColor }"></span>
                    <a :href="`/nt/risk?ID=${item.Risk_ID}`" :style="isMobile ? 'max-width: 75%;' : ''">
                        <span>{{ item.Risk_ID }} - {{ item.Risk }}</span>
                    </a>
                    <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsRisks" :row="item"
                        class="btn btn-sm btn-link p-0" :title="$t('Delete risk')" style="-bs-btn-line-height: 1;">
                        <i class="bi bi-x-circle ms-2" :class="{ 'fs-5' : isMobile}"></i>
                    </OActionDelete>
                </span>
            </div>
        </div>
    </div>
</template>